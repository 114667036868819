import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, effect, inject, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { CmsUser } from 'src/app/auth/models';
import { LinkComponent } from '@teamfoster/sdk/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NavLink } from '../../models';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../../auth/store';
import { MatTooltip } from '@angular/material/tooltip';
import { SetTheme } from 'src/app/store/actions/theme.action';
import { MatListModule } from '@angular/material/list';
import { JsonPipe, KeyValuePipe } from '@angular/common';
import { GroupByPipe } from '../../pipes/group-by.pipe';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SideNavMetaMenuComponent } from '../side-nav-meta-menu/side-nav-meta-menu.component';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { BaseMenuItem, MenuItem } from 'src/app/menu-items/models';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  standalone: true,
  imports: [
    RouterLinkActive,
    MatIconModule,
    RouterLink,
    MatTooltip,
    MatListModule,
    MatButtonModule,
    MatButtonModule,
    MatMenuModule,
    SideNavMetaMenuComponent,
    FromDictionaryPipe,
  ],
  animations: [
    trigger('slideInOut', [
      state(
        'open',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('open <=> closed', [animate('200ms ease-in')]),
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: '0' }), animate('200ms ease-in', style({ opacity: '1' }))]),
      transition(':leave', [animate('200ms ease-in', style({ opacity: '0' }))]),
    ]),
  ],
})
export class SideNavComponent {
  user = input<CmsUser>();
  menu = input<MenuItem[]>([]);
  subMenu = input<NavLink[]>([]);
  theme = input<string>();
  hasTenants = input<boolean>();
  overlayOpen = input<boolean>();
  sidebarClosed = signal(false); // manually close/open on desktop

  triggerSearch = output<void>();
  triggerTenantSwitcher = output<void>();

  hasSidebar = output<boolean>();

  activeMenuGuid: WritableSignal<string | null> = signal(window.localStorage.getItem('activeMenuGuid') || this.menu()[0]?.guid);
  activeMenuItem = computed(() => (!this.sidebarClosed() ? this.menu().find(m => m.guid === this.activeMenuGuid()) : null));

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();

  //sidebarOpen = signal(false);
  //sidebarOpenEffect = effect(() => {
  //  this.hasSidebar.emit(this.sidebarOpen());
  //});

  secondaryNavOpen = signal<string | null>(null);

  hasSidebarEffect = effect(() => {
    this.hasSidebar.emit(!!this.activeMenuItem()?.menuItems?.length);
  });

  overlayEffect = effect(
    () => {
      const isOpen = this.overlayOpen();
      if (!isOpen) {
        this.secondaryNavOpen.set(null);
      }
    },
    { allowSignalWrites: true }
  );

  shortcutSearch() {
    // return for windows ctrl + p and for mac cmd + p
    const key = navigator.platform.toUpperCase().indexOf('MAC') >= 0 ? '⌘' : 'Ctrl + ';
    return ` (${key}K)`;
  }

  activateMenuItem(guid: string) {
    this.activeMenuGuid.set(guid);
    this.sidebarClosed.set(false);
    window.localStorage.setItem('activeMenuGuid', guid);
  }

  asMenuItems(items: BaseMenuItem[]): MenuItem[] {
    return items as MenuItem[];
  }
}
