import { Routes } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { NotFoundComponent } from './404.component';
import { CheckAuthGuard } from './auth/guards/checkAuth.guard';
import { MenuGuard } from './navigation/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, CheckAuthGuard, MenuGuard];

export const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./dashboard/dashboard.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'content',
    loadChildren: () => import('./content/content.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'media',
    loadChildren: () => import('./media/media.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
