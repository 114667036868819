<!--<div class="d-flex align-items-center gap-3">
  <mat-icon>sprint</mat-icon>

  <div class="d-flex flex-column align-items-start">
    <span class="flex-grow-1">
      {{ result().title }}
    </span>
    <span class="chip chip--primary chip--xs">{{ result().subTitle }}</span>
  </div>
</div>-->

<div class="d-flex align-items-center gap-3">
  <mat-icon class="me-0">{{ result().icon }}</mat-icon>

  <span class="chip chip--primary flex-shrink-0 w-auto">
    <span class="search-title" [innerHTML]="result().title | highlight: searchQuery()"></span>
    <br />
    <!-- <mat-chip-set> -->
    <ol class="d-flex gap-1 mat-body-small text-outline breadcrumbs">
      <li class="align-items-center d-flex gap-1">
        @for (crumb of result().breadcrumbs; track $index) {
          <span class="d-flex align-items-center gap-1">
            @if (crumb.icon) {
              <mat-icon class="chip-icon-image small--icon me-0">{{ crumb.icon }}</mat-icon>
            }
            <span [innerHTML]="crumb.name | highlight: searchQuery()"></span>
          </span>
          @if (!$last) {
            <mat-icon class="chip-icon-image mx-n1">chevron_right</mat-icon>
          }
        }
      </li>
    </ol>
    <!-- </mat-chip-set> -->
  </span>
</div>
