<div
  class="command-palette overflow-hidden mat-elevation-z8 bg-primary-subtle"
  [class.rounded]="!auto.showPanel"
  [class.rounded-top]="auto.showPanel"
>
  <div class="position-relative" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <mat-form-field [subscriptSizing]="'dynamic'">
      <mat-icon matPrefix>search</mat-icon>

      <span matPrefix>
        @for (item of tabItems(); track $index) {
          {{ item }}&nbsp;/&nbsp;
        }
      </span>
      <input
        #queryInput
        matInput
        type="search"
        [placeholder]="'command-palette:search-placeholder' | fromDictionary"
        [formControl]="queryControl"
        (keyup.tab)="tab()"
        (keydown.backspace)="backspace()"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="origin"
        (focus)="queryInput.select()"
      />
    </mat-form-field>

    @if (loading$()) {
      <mat-progress-bar class="mat-form-field-loader" mode="indeterminate"></mat-progress-bar>
    }
    <!-- <div class="p-2 d-flex gap-1 bg-surface-container-high">
      <mat-chip-listbox (change)="filterOn($event)" class="mat-mdc-chip-set-dstacked" aria-label="Filter results">
        <mat-chip-option value="" selected>All</mat-chip-option>
        @for (filter of filters$(); track filter.value) {
          <mat-chip-option [value]="filter.value">{{ filter.label }}</mat-chip-option>
        }
      </mat-chip-listbox>
    </div> -->
  </div>
  <mat-autocomplete
    (optionSelected)="activate($event)"
    class="bg-primary-subtle rounded-bottom overflow-auto command-palette-results-panel custom-scrollbar"
    #auto="matAutocomplete"
    [autoActiveFirstOption]="true"
  >
    @if (resultCount()) {
      @for (group of filteredResults(); track group.type; let last = $last) {
        <mat-optgroup [label]="group.type">
          @for (result of group.results; track result.id) {
            <mat-option [value]="result">
              @switch (result.type) {
                @case ('Tenant') {
                  <app-search-result-tenant [result]="result"></app-search-result-tenant>
                }
                @case ('Menu') {
                  <app-search-result-menu [searchQuery]="q$()" [result]="result"></app-search-result-menu>
                }
                @default {
                  <app-search-result [result]="result"></app-search-result>
                }
              }
            </mat-option>
          }
        </mat-optgroup>
      }
    }

    @if (resultCount() === 0 && queryInput.value && !loading$()) {
      <mat-option class="empty-state" disabled>{{ 'command-palette:empty-state-text' | fromDictionary }}</mat-option>
    }
  </mat-autocomplete>
</div>
