<ul class="meta-menu" [ngClass]="cssClasses()">
  @if (hasTenants()) {
    <li>
      <button
        mat-icon-button
        (click)="closeOverlay.emit(); triggerTenantSwitcher.emit()"
        [matTooltip]="('navigation:switch-tenant' | fromDictionary) + shortcutTenant()"
        [matTooltipPosition]="'right'"
        [matTooltipClass]="'tooltip-primary'"
        color="tonal"
      >
        <mat-icon class="icon-secondary-fixed-variant">exit_to_app</mat-icon>
      </button>
    </li>
  }

  <li>
    @if (theme(); as theme) {
      <button
        mat-icon-button
        color="tonal"
        (click)="setTheme(theme)"
        [matTooltip]="'general:set-theme-to-' + (theme === 'dark' ? 'light' : 'dark') | fromDictionary"
        [matTooltipPosition]="'right'"
        [matTooltipClass]="'tooltip-primary'"
      >
        <mat-icon class="tab-button__icon">{{ theme === 'dark' ? 'light' : 'dark' }}_mode</mat-icon>
        <!--<span>{{ (theme === 'dark' ? 'navigation:light-theme' : 'navigation:dark-theme') | fromDictionary }}</span>-->
      </button>
    }
  </li>

  <li>
    <button class="user-thumb my-2 mx-auto" [matMenuTriggerFor]="accountMenu">
      <app-user-avatar [user]="user()"></app-user-avatar>

      <!-- <img class="w-100" [src]="user.avatar" [alt]="user.firstName" /> -->
    </button>

    <mat-menu #accountMenu="matMenu">
      <a mat-menu-item [routerLink]="['/', 'settings']" (click)="closeOverlay.emit()">
        <mat-icon class="icon-secondary-fixed-variant">settings</mat-icon>
        <span>{{ 'general:settings' | fromDictionary }}</span>
      </a>

      <button mat-menu-item (click)="signOut()">
        <mat-icon class="tab-button__icon">logout</mat-icon>
        <span>{{ 'navigation:sign-out' | fromDictionary }}</span>
      </button>
    </mat-menu>
  </li>
</ul>
